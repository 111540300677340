import React from 'react'
import { useCallback, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

import Link from '@material-ui/core/Link'
import { useIntl } from 'gatsby-plugin-intl'
import { CustomButton } from '../CustomButton'
import { MeterSqrIcon } from '../../icons/MeterSqrIcon'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import clsx from 'clsx'
import { submitForm } from '../../utils/submitForm'
import TextField from '@material-ui/core/TextField'
import GoogleMapsAutoComplete from '../AdressAutoComplete'
import { Checkbox } from '@material-ui/core'
import theme from '../../Theme/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: `'Gotham Book', serif`,
    minHeight: '380px',
    width: '100%',
    backgroundColor: theme.palette.info.main,
    padding: '6em 2em',
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
      padding: '4em 2em',
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(25),
    fontFamily: `'Gotham Bold', serif`,
    textTransform: 'uppercase',
    textAlign: 'center',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(18),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  formControl: {
    margin: theme.spacing(3),
  },
  content: {
    fontSize: '1em',
    marginBottom: theme.spacing(5),
  },
  consent: {
    fontSize: '.8em',
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(1),
  },
  transparent: {
    backgroundColor: 'transparent',
  },
  w100: {
    width: '100%',
  },
  mb2: {
    marginBottom: theme.typography.pxToRem(10),
  },
  label: {
    fontFamily: "'Gotham Book', serif",
  },
  labelTypo: {
    fontFamily: "'Gotham Book', serif",
    fontWeight: 100,
    fontSize: '0.8rem',
  },
  btnMargin: {
    marginTop: '3rem',
    [theme.breakpoints.between('xs', 'xs')]: {
      marginTop: '1rem',
    },
  },
  pr: {
    paddingRight: '20px',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    },
  },
  containerClass: {
    marginBottom: 30,
    flex: 1,
  },
  labelClass: {
    color: '#000',
  },
  inputClass: {
    '& input': {
      fontFamily: `'Gotham Book', serif`,
      fontSize: '.8rem',
    },
  },
  checkBoxContainer: {
    display: 'flex',
    alignItems: 'start',
    flexWrap: 'wrap',
  },
  checkBoxSuperLabel: {
    marginTop: '.5rem',
    marginRight: '1rem',
    whiteSpace: 'nowrap',
  },
  message: {
    marginTop: '1.5rem',
  },
}))

interface EstimateProps {
  defaultOpen?: boolean
}

const Square = styled.div`
  width: 15px;
  height: 15px;
  border: 1px solid #606060;
  margin: 2px;
  background: transparent;
`
const FullSquare = styled.div`
  width: 15px;
  height: 15px;
  border: 1px solid #606060;
  margin: 2px;
  background: #979797;
`
const SquareRed = styled.div`
  width: 15px;
  height: 15px;
  border: 1px solid;
  margin: 2px;
  background: transparent;
`

const CustomCheckBox = ({
  error = [''],
  classes = { label: '', labelTypo: '' },
  value = {},
  onChange = () => {},
  name = '',
  label = '',
  checked = false,
}) => (
  <FormControlLabel
    className={clsx(classes.label)}
    control={
      <Checkbox
        icon={error.includes(name) && !error.includes('initial') ? <SquareRed /> : <Square />}
        value={value}
        checkedIcon={<FullSquare />}
        checked={checked}
        onChange={onChange}
        name={name}
      />
    }
    label={<Typography className={classes.labelTypo}>{label}</Typography>}
  />
)

export const Estimate: React.FC<EstimateProps> = (props) => {
  const { defaultOpen } = props
  const intl = useIntl()
  const classes = useStyles()
  const [isExpand, setIsExpand] = useState<boolean>(defaultOpen || false)
  const [error, setError] = useState(['initial'])
  const [submited, setSubmited] = useState(false)

  const [state, setState] = useState({
    propertyType: null,
    adresse: '',
    rooms: null,
    surfaceHabitable: null,
    etage: null,
    lastName: null,
    email: null,
    phone: null,
    message: '',
    form: 'FE1',
    consent: false,
  } as any)

  const handleChange = useCallback(
    (
      event: React.ChangeEvent<{
        name?: string
        value: unknown
        textContent?: any
        checked?: boolean
      }>,
    ) => {
      if (event && event.target && (event.target.name || event.target.textContent)) {
        setError([])
        setState({
          ...state,
          [event.target.name || ('adresse' as string)]:
            (event.target.name == 'consent' ? event.target.checked : event.target.value) ||
            event.target.textContent,
        })
      }
    },
    [state],
  ) as any

  const onSubmit = async () => {
    setIsExpand(true)

    const { message, consent, ...keyToValidate } = state
    const validateField = Object.keys(keyToValidate).filter((key: string) => !state[key])

    if (validateField.length) {
      setError(validateField)
    } else {
      const result = await submitForm(state)
      if (result) {
        setSubmited(true)
        setError([])

        if (typeof window !== 'undefined') {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: 'estimate_form',
          })
        }
      }
    }
  }

  return (
    <Grid container justifyContent="center" className={classes.root}>
      <Grid container sm={11} md={10}>
        <Grid item xs={12} className={classes.heading}>
          {intl.formatMessage({ id: 'estimate.estimateMyProperty' })}
        </Grid>
        <Grid item md={6} xs={12} className={classes.pr}>
          <div className={classes.checkBoxContainer}>
            <div className={classes.checkBoxSuperLabel}>
              {intl.formatMessage({ id: 'estimate.kindOfGood' })}
            </div>
            <div className={classes.containerClass}>
              {['maison-villa', 'appartement'].map((key) => (
                <CustomCheckBox
                  key={key}
                  classes={classes}
                  error={error}
                  onChange={handleChange}
                  name="propertyType"
                  value={key}
                  checked={state.propertyType === key}
                  label={intl.formatMessage({ id: `estimate.${key}` })}
                />
              ))}
            </div>
          </div>
          <div className={classes.checkBoxContainer}>
            <div className={classes.checkBoxSuperLabel}>
              {intl.formatMessage({ id: 'estimate.numberOfPieces' })}
            </div>
            <div className={classes.containerClass}>
              {['1', '2', '3', '4', '5', '6+'].map((key) => (
                <CustomCheckBox
                  key={key}
                  classes={classes}
                  error={error}
                  onChange={handleChange}
                  name="rooms"
                  value={key.replace('+', '')}
                  checked={state.rooms?.replace('+', '') === key.replace('+', '')}
                  label={key}
                />
              ))}
            </div>
          </div>
          <Grid item md={6}>
            <TextField
              placeholder={intl.formatMessage({ id: 'yourName' })}
              name="lastName"
              error={error.includes('lastName')}
              onChange={handleChange}
              className={clsx(classes.w100, classes.containerClass, classes.inputClass)}
            />
            <TextField
              placeholder={intl.formatMessage({ id: 'yourEmail' })}
              name="email"
              error={error.includes('email')}
              onChange={handleChange}
              className={clsx(classes.w100, classes.containerClass, classes.inputClass)}
            />
            <TextField
              placeholder={intl.formatMessage({ id: 'yourNumber' })}
              name="phone"
              error={error.includes('phone')}
              onChange={handleChange}
              className={clsx(classes.w100, classes.containerClass, classes.inputClass)}
            />
          </Grid>
        </Grid>
        <Grid item md={6} xs={12}>
          <GoogleMapsAutoComplete
            onChange={handleChange}
            error={error.includes('adresse')}
            className={clsx(classes.w100, classes.containerClass, classes.inputClass)}
            InputLabelProps={{ className: classes.labelClass }}
            inputProps={{
              className: classes.inputClass,
            }}
          />
          <Grid container>
            <div
              style={{ display: 'flex', alignItems: 'center', paddingRight: '1rem' }}
              className={clsx(classes.containerClass)}
            >
              <TextField
                placeholder={intl.formatMessage({ id: 'estimate.area' })}
                name="surfaceHabitable"
                onChange={handleChange}
                error={error.includes('surfaceHabitable')}
                className={clsx(classes.w100, classes.inputClass)}
              />
              <MeterSqrIcon style={{ fontSize: '20px' }} />
            </div>
            <TextField
              placeholder={intl.formatMessage({ id: 'estimate.floor' })}
              name="etage"
              error={error.includes('etage')}
              onChange={handleChange}
              className={clsx(classes.containerClass, classes.inputClass)}
            />
            <Grid xs={12} item className={clsx(classes.message)}>
              {intl.formatMessage({ id: 'yourMessage' })}...
            </Grid>
            <Grid xs={12} item>
              <TextareaAutosize
                className={clsx(classes.w100, classes.transparent, classes.mb2)}
                aria-label="minimum height"
                name="message"
                onChange={handleChange}
                minRows={8}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid md={9} xs={12} container className={classes.consent} justifyContent="center">
        <Grid style={{ textAlign: 'center' }}>
          <span>
            {intl.formatMessage({ id: 'legalConsent.estimate.agree' })}
            <Checkbox
              size="small"
              icon={
                state.consent == false && !error.includes('initial') ? <SquareRed /> : <Square />
              }
              checkedIcon={<FullSquare />}
              checked={state.consent}
              onChange={handleChange}
              name={'consent'}
              style={{ padding: '0 0 0 4px' }}
            />
          </span>
        </Grid>
        <Grid style={{ textAlign: 'center', paddingTop: '12px' }}>
          <span>
            {intl.formatMessage({ id: 'legalConsent.estimate.moreInformation' })}
            <Link href={'/' + intl.locale + '/legal-privacy-policy'} underline="none">
              {intl.formatMessage({ id: 'clickHere' })}
            </Link>
          </span>
        </Grid>
      </Grid>
      <Grid md={9} xs={12} container className={classes.btnMargin} justifyContent="center">
        <Grid style={{ textAlign: 'center' }}>
          {submited ? (
            intl.formatMessage({ id: 'send_confirm' })
          ) : (
            <CustomButton
              onClick={onSubmit}
              textTransform="inherit"
              darkBoarder
              //height={isExpand ? '40px' : '50px'}
            >
              <span style={{ fontSize: '0.8rem', padding: '0 0px', fontWeight: 100 }}>
                {intl.formatMessage({ id: isExpand ? 'send' : 'send' })}
              </span>
            </CustomButton>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Estimate
